import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ContextController from './CONTROLLERS/ContextController';
import { vakeup } from './TOOLS/UserQueries'

function App() {

  const [ loading, setLoading ] = useState(true);

  useEffect(() =>{
    vakeup()
    .then(response => { setLoading(false)})
    .catch(error => { console.log('VAKEUP ERROR ', error) })
  }, [])

  return (
    <div className = 'canvas'>
      {loading ? <div ><ReactLoading type={'spinningBubbles'} color={'#808080'} height={'10%'} width={'10%'}  /> </div>
      : <ContextController />}
      </div>
  );
}

export default App;
