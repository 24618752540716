import React, { useState, useEffect } from 'react'

interface KnowElementProps {
    getPdf: ( pdfname: string ) => void;
    name: string; 
}
 
const KnowElement: React.FunctionComponent<KnowElementProps> = ( props ) => {



    return ( 
        <div className = 'knowElement'>
            { props.name.length > 0 ? <div className = 'pdfIcon'></div> : ''}
            
            <span className = 'pdfName' onClick={(e) => props.getPdf(props.name)}>{props.name}</span>
        </div>
     );
}
 
export default KnowElement;