import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import { useNavigate } from 'react-router-dom'
import PopupContext from '../MODELS/contexts/popupContext'

interface MenuProps {
    menuObject: { menuContainer: string, buttonUp: string, buttonDown: string }
}

const Menu: React.FunctionComponent<MenuProps> = props => {

    const navigate = useNavigate()
    const { popupState, updatePopupState } = useContext(PopupContext);

    useEffect(() => {

    }, [])

    const clickUp = () => { 
        switch (props.menuObject.menuContainer) {
            case 'menuContainerGreeting':
                navigate('/auth') 
                break;
            case 'menuContainerKnow':
                navigate('/search') 
                break;
            case 'menuContainerSearch':
                navigate('/know') 
                break;
            case 'menuContainerAdmin':
                navigate('/know') 
                break;
            default:
                navigate('/auth')
          }
        
    }

    //const clickDown = () => { navigate('/about') }
    const clickDown = () => { 
        switch (props.menuObject.menuContainer) {
            case 'menuContainerAdmin':
                navigate('/search') 
                break;
            default:
                navigate('/auth')
          }
     }

    return(
        <div className = { props.menuObject.menuContainer }>
            <div className = { props.menuObject.buttonUp } onClick = {() => clickUp()}></div>
            <div className = { props.menuObject.buttonDown } onClick = {() => clickDown()}></div>
        </div>
   
   
        
     
    )

}

export default Menu