import React, { useRef, useEffect, useContext } from 'react'


function Video(props) {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

    useEffect(() => { attemptPlay();
    }, [])



  return (
    <div>
      <video
        style={{ maxWidth: "100%", width: "800px", height: "100%", margin: "0 auto" }}
        playsInline
        loop
        muted
        controls
        alt="All the devices"
        src="../video/sybrilloNEW.mp4"
        ref={videoEl}
      />
    </div>

    );

  }


export default Video;
