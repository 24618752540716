import IKnow from '../MODELS/know';
import IUser from '../MODELS/user'
import bcrypt from 'bcryptjs'
import { SALT } from '../TOOLS/config'
import { saveDatas } from '../TOOLS/FtpQueries'

export const doHash = (password: string): any => {
  return bcrypt.hashSync(password, SALT)
}

export const searchEmail = (authUser: IUser, users: any) => {
  if(users.find((databaseUser: any) => (databaseUser.email === authUser.email)) !== undefined){
      return true
  }else{
      return false
  }
}

export const login = (authUser: IUser, users: any): any => {

  console.log('USERS ', users)
  let loginAnswer: {auth: boolean, message: string, actUser: IUser} = { auth: false, message: "", actUser: {name: '', email: '', password: '', role: ''}}
  let foundUser: boolean = false
  let correctPass: boolean = false

  for(let i = 0; i < users.length; i++){
    if(users[i].email === authUser.email){
      foundUser = true;
      if(bcrypt.compareSync(authUser.password, users[i].password)){
        correctPass = true;
        loginAnswer.actUser = users[i]
        break
      }
    }
  }

  if(foundUser){
    if(correctPass){
      loginAnswer.auth = true;
      loginAnswer.message = loginAnswer.actUser.name
    }else{
      loginAnswer.auth = false;
      loginAnswer.message = 'Nem megfelelő felhasználó/jelszó kombináció'
    }
  }else{
    loginAnswer.auth = false;
    loginAnswer.message = 'Nincs ilyen regisztrált felhasználó, kérem regisztráljon!'
  }

  return loginAnswer

}

export const register = (authUser: IUser, users: any): any => {

  console.log('REGISTER ', users)

  return new Promise<any>((resolve, reject) => {
    users.push(authUser)
    
    saveDatas(users, 'sybrillo', 'users.csv')
    .then((response: any) => {
      resolve(response)
    })
    .catch( error => {
      console.log(error);
      reject(error)
    });
  })
}

export const modify = (action: string, email: string, users: any): any => {

  console.log('MODIFY ', action, email, users)

  if(action === 'approved'){
    users.map((user: IUser) => ( user.email === email ? user.role = action : '' ))
  }

  console.log('MODIFIEDUSERS ', users)

  return new Promise<any>((resolve, reject) => {
       
    saveDatas(users, 'sybrillo', 'users.csv')
    .then((response: any) => {
      resolve(response)
    })
    .catch( error => {
      console.log(error);
      reject(error)
    });
  })
  
}





