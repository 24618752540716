import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import KnowList from './KnowList'
import IUser from '../MODELS/user';


interface KnowProps {
  getPdf: ( pdfname: string ) => void;
  showPdf: ( pdfname: string ) => void;
  menuObject: { menuContainer: string, buttonUp: string, buttonDown: string }
  knows: { id: string, name: string }[];
  actualUser: IUser;
}


const Know: React.FunctionComponent<KnowProps> = props => {



    
    useEffect(() => {
        
    }, [])

    return(
        <div className = 'knowContainer'>
          <div className = 'actualUserContainer'>
            <div>Bejelentkezve: </div>
            <div>{' ' + props.actualUser.name}</div>
          </div>
          <div className = 'know'>
            <div className = 'knowWrapper'>
              <KnowList knows = { props.knows } getPdf = { props.showPdf }/>
            </div>
          </div>
          <Menu menuObject = { props.menuObject }/>
        </div>

    )
}

export default Know