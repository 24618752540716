import React, { useEffect, useState, useContext } from 'react';
import Admin from '../COMPONENTS/Admin';
import IUser from '../MODELS/user'
import IUsers from '../MODELS/interfaces/userInterface'
import { mailSender } from '../TOOLS/MailSender'
import PopupContext from '../MODELS/contexts/popupContext';
import UserContext from '../MODELS/contexts/userContext';
import { modify } from '../TOOLS/UserOperations'


const AdminController: React.FunctionComponent = props => {

  const emailObject: { email: string, title: string, text: string } = { email: "", title: "", text: "" };
  const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = { menuContainer: "menuContainerAdmin", buttonUp: "menuAdminButtonUp", buttonDown: "menuAdminButtonDown" };
  const { users, updateUsers, actualUser } = useContext(UserContext);
  const { popupState, updatePopupState } = useContext(PopupContext);
  
    useEffect(() => {

     }, [])

   
     const handleUsers = (action: string, email: string) => {
      emailObject.email = email;
      let popupStateTemp = JSON.parse(JSON.stringify(popupState))
      if(action === 'approved'){
        emailObject.title = 'Az adminisztrátor jóváhagyta a Sybrillo.tudasbazis.hu oldalon a regisztrációját.';
        emailObject.text = 'Bejelentkezés után el fogja érni a tudásbázis adatait'
        popupStateTemp.popupTitle = 'Jóváhagyott felhasználó!';
        modify(action, email, users)
        setTimeout(() => {updateUsers()}, 2000)
      }else{
        emailObject.title = 'Az adminisztrátor elutasította a Sybrillo.tudasbazis.hu oldalon a regisztrációját.';
        emailObject.text = 'További információ a +36 00 000 0000 telefonszámon'
        popupStateTemp.popupTitle = 'Elutasított felhasználó!';
      }
      //MAILTOUSER
      //mailSender(emailObject)
      //POPUP
      popupStateTemp.popupVisibility = 'popupContainer';
      popupStateTemp.popupMessage = email;
      updatePopupState(popupStateTemp);
      
    }
    

    return(
      <>
        <Admin menuObject = { menuObject } handleUsers = { handleUsers } users = { users } actualUser = { actualUser }/>
      </>
     )

}

export default AdminController