import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import { useForm } from 'react-hook-form';
import IUser from '../MODELS/user'

interface FormLoginProps {
    handleLogin: ( e: IUser ) => void,
}


const FormLogin: React.FunctionComponent<FormLoginProps> = props => {

    useEffect(() => {
        
    }, [])

    const { register, handleSubmit, formState: { errors }, } = useForm<IUser>();
    
    const onSubmit = (data: IUser) => { props.handleLogin(data) }


    return(
        <div className = 'loginContainer'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className = 'loginRow'>
                    E-mail
                    <input {...register('email', { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                    {errors.email && <p>Adjon meg e-mail címet!</p>}
                </div>
                <div className = 'loginRow'>
                    Jelszó
                    <input {...register('password', { required: true})} />
                    {errors.password && <p>Adja meg a jelszót, amivel regisztrált!</p>}   
                </div>
            <input className = 'butt' type="submit" />
            </form>
        </div>
        
     
    )

}

export default FormLogin