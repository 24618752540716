import React, { useEffect, useState, useContext } from 'react'
import IPopup from '../MODELS/popup'
import '../STYLE/style.css'
import PopupContext from '../MODELS/contexts/popupContext'

interface IPopupProps {
    handleClick: ( email: string ) => void;
}

const PopupController: React.FunctionComponent<IPopupProps> = (props) => {

    const [ width, setWidth ] = useState(window.innerWidth);
    const [ height, setHeight ] = useState(window.innerHeight);
    const [ keyboard, setKeyboard ] = useState(false);
    const { popupState, updatePopupState } = useContext(PopupContext);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        window.addEventListener("resize", () => setHeight(window.innerHeight));

    }, [])

    return(
        <>
            <div className = 'popupTitleWrapper'>
                <div>{popupState.popupTitle}</div>
            </div>
            <div className = 'popupContentWrapper' >
            <div className = 'popupMessage'>{popupState.popupMessage}</div>
            <div className = 'butt' onClick = {() => props.handleClick('xxx')}>OK</div>
            </div>
            
        </>
        
     
    )

}

export default PopupController