import React, { useEffect, useState, useContext } from 'react';
import IUser from '../MODELS/user';
import UserContext from '../MODELS/contexts/userContext';
import Auth from '../COMPONENTS/Auth';
import PopupContext from '../MODELS/contexts/popupContext';
import KnowContext from '../MODELS/contexts/knowContext';
import { useNavigate } from 'react-router-dom'
import { mailSender } from '../TOOLS/MailSender'
import { login, register, doHash, searchEmail } from '../TOOLS/UserOperations'

interface IAuthProps {

}


const AuthController: React.FunctionComponent<IAuthProps> = (props) => {

  const { actualUser, updateActualUser, users, updateUsers } = useContext(UserContext);
  const { popupState, updatePopupState } = useContext(PopupContext);
  const [ user, setUser ] = useState('');
  const navigate = useNavigate()
  const { knows } = useContext(KnowContext);
  const emailObject: { email: string, title: string, text: string } = { email: "", title: "", text: "" };

  useEffect(() => {

  }, [user])



    const handleLogin = (authUser: IUser): void => {
      let answer = login(authUser, users)
      if(answer.auth){
        let popupStateTemp = JSON.parse(JSON.stringify(popupState))
          popupStateTemp.popupVisibility = 'popupContainer';
          popupStateTemp.popupTitle = 'Sikeres bejelentkezés!';
          popupStateTemp.popupMessage = answer.name;
          popupStateTemp.popupTheme = 'toKnow';
          updateActualUser(answer.actUser);
          setUser(answer.actUser.name);
          updatePopupState(popupStateTemp);
          if(answer.actUser.role === 'admin'){
            navigate('/admin')
          }else{
            navigate('/know')
          }
      }else {
          let popupStateTemp = JSON.parse(JSON.stringify(popupState))
          popupStateTemp.popupVisibility = 'popupContainer';
          popupStateTemp.popupTitle = 'Hibás bejelentkezés!';
          popupStateTemp.popupMessage = answer.message;
          updatePopupState(popupStateTemp)
        }
      }

    const handleRegister = (authUser: IUser): void => {

      let tempUser = {
          name: authUser.name,
          email: authUser.email,
          password: doHash(authUser.password),
          role: 'registered'
        }
       
      if(searchEmail(tempUser, users)){
          let popupStateTemp = JSON.parse(JSON.stringify(popupState))
          popupStateTemp.popupVisibility = 'popupContainer';
          popupStateTemp.popupTitle = 'Hibás regisztráció!';
          popupStateTemp.popupMessage = 'A megadott e-mail címmel már regisztráltak hozzánk, jelentkezzen be, vagy használjon más e-mailt!';
          updatePopupState(popupStateTemp)
      }else{
          console.log('REGISTERR ', users)
          register(tempUser, users)
          .then((response: any) => { 
            //POPUP
            let popupStateTemp = JSON.parse(JSON.stringify(popupState))
            popupStateTemp.popupVisibility = 'popupContainer';
            popupStateTemp.popupTitle = 'Sikeres regisztráció!';
            popupStateTemp.popupMessage = 'Regisztrációja jóváhagyásáról az adminisztrátor e-mailben fogja értesíteni!';
            updatePopupState(popupStateTemp)

            //EMAILTOADMIN
            emailObject.email = "szempontstudio@gmail.com";
            emailObject.title = 'új regisztráló a Sybrillo.tudasbazis.hu oldalon'
            emailObject.text = 'A Sybrillo.tudasbazis.hu oldalon belépve tudja jóváhagyni / elutasítani!'
            setTimeout(() => {updateUsers()}, 2000)
            //mailSender(emailObject);
            })
      }
      
    }

    return(
      <>
      <>{console.log('AUTH USERS ', users)}</>
        <Auth handleRegister = { handleRegister } handleLogin = { handleLogin } loggedUser = { user } />
      </>
     )

}

export default AuthController