import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import KnowElement from './KnowElement'


interface KnowListProps {
  getPdf: ( pdfname: string ) => void;
  knows: { id: string, name: string }[];
}


const KnowList: React.FunctionComponent<KnowListProps> = props => {



    
    useEffect(() => {
        
    }, [])

    return(
      <div className = 'knowList'>
        {props.knows.map((know, index) => <KnowElement key = { index} name = { know.name } getPdf = { props.getPdf }/> )}
      </div>
    )
}

export default KnowList