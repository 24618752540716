import { createContext } from 'react';
import IKnows from '../interfaces/knowInterface';


const defaultState = {
   knows: [{id: '', name: ''}],
   updateKnows: () => {},
};

const UserContext = createContext<IKnows>(defaultState);

export default UserContext