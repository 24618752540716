import React, { useEffect, useState, useContext  } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Greeting from '../COMPONENTS/Greeting'
import AuthController from '../CONTROLLERS/AuthController'
import KnowController from '../CONTROLLERS/KnowController'
import SearchController from '../CONTROLLERS/SearchController'
import AdminController from '../CONTROLLERS/AdminController'
import PopupContext from '../MODELS/contexts/popupContext';
import IPopup from '../MODELS/popup';
import Upload from '../COMPONENTS/Upload'


interface RoutingProps {
 
}

const RoutingController: React.FunctionComponent<RoutingProps> = props => {

    const { popupState, updatePopupState } = useContext(PopupContext);
    const [ popupX, setPopupx ] = useState<IPopup>();

    useEffect(() => {

    }, [popupX])


    return(
        <BrowserRouter >
            <div className = 'contentContainer'>
                <Routes>
                    <Route path = "/" element = { <Greeting /> }></Route>
                    <Route path = "/auth" element = { <AuthController /> }></Route>
                    <Route path = "/know" element = { <KnowController /> }></Route>
                    <Route path = "/search" element = { <SearchController /> }></Route>
                    <Route path = "/admin" element = { <AdminController /> }></Route>
                    <Route path = "/upload" element = { <Upload /> }></Route>
                    <Route path = "*" element = { <Greeting /> }></Route>
                </Routes>
            </div>
        </BrowserRouter>
    )

}

export default RoutingController