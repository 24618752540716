import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import { useForm } from 'react-hook-form';
import axios from 'axios';


function FormUpload(props) {

    useEffect(() => {
        
    }, [])

    const { register, handleSubmit } = useForm();
    const [ file, setFile ] = useState('')
    const [ filename, setFilename ] = useState('Tallózás')
    const [ uploadedFle, setUploadedFile ] = useState({})
    
    /*
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("file", data.file[0]);
        console.log('ONSUBMIT ', formData)
   
        const res = await fetch("https://homalyzona.herokuapp.com/database/uploaddata", {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
     
        alert(JSON.stringify(`${res.message}, status: ${res.status}`));
        
    };
    */
    const onChange = e => {
        
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    }

    const onSubmit = async e => {
        e.preventDefault();
        console.log(' FILE ', file)
        const formData = new FormData();
        formData.append("picture", file);
        formData.append("filename", "picname")

        console.log('FORMDATA ', formData)
        try{
            //const res = await axios.post("https://homalyzona.herokuapp.com/database/upload", formData, {
            const res = await axios.post("http://localhost:3000/database/upload", formData, {
            //const res = await axios.post("http://localhost:3000/database/sftpupload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
            });
            const { fileName, filePath } = res.data;
            setUploadedFile({ fileName, filePath });
        }catch(err){
            if(err.response.status === 500){
                console.log('SERVERERROR')
            }else{
                console.log(err.response.data.message)
            }
        }
    }

    return(
        <div className = 'loginContainer'>
            <div className = 'loginRow'>
                {/*
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="file" {...register("file")} /><input type="submit" />
                </form>
                */}

                <form encType = 'multipart/form-data' onSubmit = { onSubmit }>
                    <input type = 'file' name = 'picture' onChange = { onChange }/>
                    <label>{filename}</label>
                    <input type = 'submit' value = 'upload' />
                </form>
            </div>
        </div>
        
     
    )

}

export default FormUpload