import axios, { AxiosResponse } from 'axios'
import IUser from '../MODELS/user';

let temp: IUser;
let userArray: IUser[] = [];
let menuArray: any = [];

//Promise IKnowledge típusú objektumokat tartalmazó tömbbel tér vissza
export const vakeup = () => {
  return new Promise<string>((resolve, reject) => {
    axios.get<string>('https://homalyzona.herokuapp.com/')
    .then((response: AxiosResponse) => { resolve('OK'); })
    .catch(error => { reject(error); })
  })
};


//Promise IUser típusú objektumokat tartalmazó tömbbel tér vissza
export const getUsers = () => {
  userArray.length = 0;
  return new Promise<IUser[]>((resolve, reject) => {
    axios.get<IUser>('https://homalyzona.herokuapp.com/sybrillousers/allusers')
    .then((response: AxiosResponse) => {
        response.data.map((element: IUser) => (
        (temp = {name: element.name, email: element.email, password: element.password, role: element.role},
        userArray.push(temp))
        ))
        resolve(userArray);
    })
    .catch(error => {
      reject(error);
    })
  })
};

export const insertUser = (user: IUser) => {
  return new Promise((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/sybrilloUsers/insertUser', {
      name : user.name,
      email : user.email,
      password : user.password,
      role : user.role
    })
    .then(response => { resolve(response.data) })
    .catch( error => { reject(error) });
  })
};

export const login = (user: IUser) => {
  return new Promise((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/sybrilloUsers/login', {
      name : user.name,
      email : user.email,
      password : user.password,
      role : user.role
    })
    .then(response => { resolve(response.data) })
    .catch( error => { reject(error) });
  })
};

export const findByEmail = (user: IUser) => {
  return new Promise((resolve, reject) => {
    axios.post('https://homalyzona.herokuapp.com/sybrilloUsers/findbyemail', {
      name : user.name,
      email : user.email,
      password : user.password,
      role : user.role
    })
    .then(response => { resolve(response.data) })
    .catch( error => { reject(error) });
  })
};

const updateUser = async (id: number, user: IUser) => {
  const response = await axios.patch(`https://homalyzona.herokuapp.com/sybrilloUsers/update/${id}`, {
      name : user.name,
      email : user.email,
      password : user.password,
      role : user.role
  })
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.data
  return data
};

export const modifyUser = (action: string, email: string) => {
  let tempUser = {
    email: email,
    name: '',
    password: '',
    role: 'approved'
  }
  if(action === 'approved'){
    findByEmail(tempUser)
    .then((response: any) => { 
      tempUser.name = response.result.rows[0].name;
      tempUser.password = response.result.rows[0].password
      updateUser(response.result.rows[0].id, tempUser)

    })
    .catch( error => { console.log('USERQUE FINDBY ERROR', error) });
  }else{

  }

};