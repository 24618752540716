import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import FormUpload from '../COMPONENTS/FormUpload'




interface UploadProps {

}

type loginObject = { name: string, email: string, password: string }

const Upload: React.FunctionComponent<UploadProps> = props => {

    
    const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = {
        menuContainer: "menuContainerAuth",
        buttonUp: "menuButtonUpBlank",
        buttonDown: "menuButtonDownAbout"
    };

    useEffect(() => {
        
    }, [])

    const handleUpload = (theFile: any): void => {

    }

    return(
        <div className = 'authContainer'>
          <div className = 'formContainer'>
            <FormUpload handleUpload = { handleUpload } />
          </div>
          <Menu menuObject = { menuObject }/>
        </div>
        
     
    )

}

export default Upload