import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import KnowList from './KnowList'
import FormSearch from './FormSearch'
import IUser from '../MODELS/user';
import { useNavigate } from 'react-router-dom'
import { getFtpList } from '../TOOLS/FtpQueries'

type FormValues = { searchWord: string; };

interface SearchProps {
  getPdf: ( pdfname: string ) => void;
  handleSearch: ( pdfname: FormValues ) => void;
  menuObject: { menuContainer: string, buttonUp: string, buttonDown: string };
  foundList: { id: string, name: string }[];
  actualUser: IUser;
}


const Search: React.FunctionComponent<SearchProps> = props => {


  const navigate = useNavigate()
    
    useEffect(() => {
        
    }, [])

    const clickUp = () => { 
      navigate('/upload') 
    }


    const clickGetFtp = () => { 
      getFtpList()
      .then(response => { 
        console.log('CLICTGETFTP ', response)
      }).catch(error => { console.log('GETUSERS ERROR ', error) })
    }

    const clickGetFile = () => { 
      window.open('http://localhost:3000/database/getfile/?filename=/' + 'test' + '.pdf', '_blank')
      //window.open('https://homalyzona.herokuapp.com/database/getfile/?filename=/' + 'xxx' + '.jpg', '_blank')
    }

    return(
        <div className = 'searchContainer'>
          <div className = 'actualUserContainer'>
            <div>Bejelentkezve: </div>
            <div>{' ' + props.actualUser.name}</div>
          </div>
          <div className = 'search'>
            <div className = 'searchWrapper'>
              <div className = 'searchComponent'> 
                <FormSearch handleSearch = { props.handleSearch } /> 
              </div>
              <div className = 'foundcomponent'>
                <KnowList knows = { props.foundList } getPdf = { props.getPdf }/>
              </div>
            </div>
          </div>
          <Menu menuObject = { props.menuObject }/>
      </div>
    )
}

export default Search