import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import { useForm } from 'react-hook-form';
import IUser from '../MODELS/user'

interface FormRegisterProps {
    handleRegister: ( e: IUser ) => void,
}


const FormRegister: React.FunctionComponent<FormRegisterProps> = props => {

    useEffect(() => {
        
    }, [])

    const { register, handleSubmit, formState: { errors }, } = useForm<IUser>();
    
    const onSubmit = (data: IUser) => { props.handleRegister(data) }


    return(
        <div className = 'registerContainer'>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className = 'loginRow'>
                Név
                <input {...register('name')} />
                {errors.name && <p>Adja meg a nevét!</p>}
            </div>
            <div className = 'loginRow'>
                Email
                <input {...register('email', { required: true })} />
                {errors.email && <p>Adja meg az E-mail címét!</p>}
            </div>
            <div className = 'loginRow'>
                Jelszó
                <input {...register('password', { required: true })} />
                {errors.password && <p>Adjon meg egy jelszót!</p>}
            </div>
            <input className = 'butt' type="submit" />
            </form>
        </div>
        
     
    )

}

export default FormRegister