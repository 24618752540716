import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import UserList from './UserList'
import IUser from '../MODELS/user';



interface AdminProps {
  handleUsers: ( action: string, email: string ) => void;
  menuObject: { menuContainer: string, buttonUp: string, buttonDown: string };
  users: { name: string; email: string; password: string; role: string }[];
  actualUser: IUser;
}


const Admin: React.FunctionComponent<AdminProps> = props => {

    
    useEffect(() => {
        
    }, [])

    return(
        <div className = 'adminContainer'>
          <div className = 'actualUserContainer'>
            <div>Bejelentkezve: </div>
            <div>{' ' + props.actualUser.name}</div>
          </div>
          <div className = 'userBox'>
            {
            <div className = 'userWrapper'>
              <UserList users = { props.users } handleUsers = { props.handleUsers }/>
            </div>
             }
          </div>
          <Menu menuObject = { props.menuObject }/>
        </div>

    )
}

export default Admin