import { createContext } from 'react';
import IUsers from '../interfaces/userInterface';


const defaultState = {
   actualUser: {name: '', email: '', password: '', role: ''},
   updateActualUser: () => {},
   users: [{name: '', email: '', password: '', role: ''}],
   updateUsers: () => {},
};

const UserContext = createContext<IUsers>(defaultState);

export default UserContext