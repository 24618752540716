import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import { useForm } from 'react-hook-form';
import IUser from '../MODELS/user'

type FormValues = { searchWord: string; };

interface FormSearchProps {
    handleSearch: ( e: FormValues ) => void,
}


const FormSearch: React.FunctionComponent<FormSearchProps> = props => {

    useEffect(() => {
        
    }, [])

    const { register, handleSubmit, formState: { errors }, } = useForm<FormValues>();
    
    const onSubmit = (data: FormValues) => { props.handleSearch(data) }


    return(
        <div className = 'searchRow'>
            <div className = 'searchLabel'>Keresőszó:</div>
            <form className = 'searchInput' onSubmit={handleSubmit(onSubmit)}>
                <input className = 'input' {...register('searchWord', { required: true})} />
                {errors.searchWord && <p>Adja meg a keresendő kifejezést!</p>}   
            <input className = 'butt' type="submit" />
            </form>
        </div>
        
     
    )

}

export default FormSearch