import React, { useEffect, useState, useCallback } from 'react';
import IUser from '../MODELS/user';
import IKnow from '../MODELS/know';
import UserContext from '../MODELS/contexts/userContext';
import KnowContext from '../MODELS/contexts/knowContext';
import PopupController from './PopupController';
import { getFtpData } from '../TOOLS/FtpQueries'


const ContextController: React.FunctionComponent = props => {

  let loaded = false;

  const splitter = (caller: string, values: any) => {
    let valueArray = values[0].split(',');
    let element = {};
    
    switch (caller) {
      case 'userKeys':
        element = {name: valueArray[0], email: valueArray[1], password: valueArray[2], role: valueArray[3]};
        return element
      case 'users':
        element = {name: valueArray[0], email: valueArray[1], password: valueArray[2], role: valueArray[3]};
        return element
      case 'pdfList':
        element = {id:values[0], name: values[1]};
        return element
    }  
  }

// ---------------------------------------------------------------------------------USER
    const [ users, setUsers ] = useState<IUser[]>([]);


    const loadUsers = (ftpData: any) => {
      let tempUsers: any[] = []
      tempUsers.push(splitter('userKeys', Object.keys(ftpData[0])))
      ftpData.map((element: any) => (
        tempUsers.push(splitter('users', Object.values(element)))
       ))
     
       setUsers(tempUsers) 
    }

    const updateUsers = () => {
      
      getFtpData('sybrillo', 'users')
      .then(response => { 
        loadUsers(response)
      }).catch(error => { console.log('GETDATAS ERROR ', error) })
    }

    const [ actualUser, setActualUser ] = useState<IUser>({name: '', email: '', password: '', role: ''});
    const updateActualUser = (user: IUser) => { setActualUser(user) }

// ---------------------------------------------------------------------------------KNOW
  const [ knows, setKnows ] = useState<IKnow[]>([]);

  const updateKnows = (ftpData: any) => {
    let tempKnows: any[] = []
    ftpData.map((element: any) => (
      tempKnows.push(splitter('pdfList', Object.values(element)))
     ))
     setKnows(users => ([...knows, ...tempKnows])) 
  }


  useEffect(() =>{
    
    if(!loaded){
      loaded = true;
      setTimeout(() => {
        getFtpData('sybrillo', 'users')
        .then(response => { 
          loadUsers(response)
          getFtpData('sybrillo', 'pdfList')
          .then(response => { updateKnows(response) })
        }).catch(error => { console.log('GETDATAS ERROR ', error) })
      }, 1000)

    }
    
  }, [])

  return(
    <>
    <UserContext.Provider value = {{actualUser, updateActualUser, users, updateUsers}}>
    <KnowContext.Provider value = {{knows, updateKnows}}>
      <PopupController />
    </KnowContext.Provider>
    </UserContext.Provider>
    </>
    )

}

export default ContextController