import React, { useEffect, useState, useContext } from 'react';
import fileDownload from 'js-file-download';
import Know from '../COMPONENTS/Know';
import { getFile } from '../TOOLS/Download'
import UserContext from '../MODELS/contexts/userContext';
import KnowContext from '../MODELS/contexts/knowContext';


const KnowController: React.FunctionComponent = props => {

  const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = {
    menuContainer: "menuContainerKnow",
    buttonUp: "menuButtonUpSearch",
    buttonDown: "menuButtonDownAbout"
};

    const { knows } = useContext(KnowContext);
    const { actualUser, updateActualUser, users, updateUsers } = useContext(UserContext);
  
    useEffect(() => {


     }, [])

    const getPdf = (pdfname: string) => { window.open('https://homalyzona.herokuapp.com/sybrilloftp/getfile/?filename=/' + pdfname + '.pdf', '_blank') }

    return(
      <>
        <Know menuObject = { menuObject } knows = { knows } getPdf = { getPdf } showPdf = { getPdf } actualUser = { actualUser }/>
      </>
     )

}

export default KnowController