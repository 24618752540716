import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import UserElement from './UserElement'
import IUser from '../MODELS/user'


interface UserListProps {
  handleUsers: ( action: string, email: string ) => void;
  users: IUser[];
}


const UserList: React.FunctionComponent<UserListProps> = props => {

    
    useEffect(() => {
        
    }, [])

    return(
      <div className = 'userList'>
        <div className = 'userElement'>
          {props.users.map((user, index) => <UserElement key = { index} user = { user } handleUsers = { props.handleUsers }/> )}
        </div>
      </div>
    )
}

export default UserList