import { createContext } from 'react';
import PopupInterface from '../interfaces/popupInterface';
import IPopup from '../popup';

const defaultState = {
   popupState: {popupVisibility: '', popupStyle: '', popupTheme: '', popupTitle: '', popupMessage: ''},
   updatePopupState: () => {},
};

const PopupContext = createContext<PopupInterface>(defaultState);

export default PopupContext