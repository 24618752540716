import React, { useState, useEffect } from 'react'
import IUser from '../MODELS/user'

interface UserElementProps {
    handleUsers: ( action: string, email: string ) => void;
    user: IUser; 
}
 
const UserElement: React.FunctionComponent<UserElementProps> = ( props ) => {


    return ( 
        <div className = 'userRow'>
            <div className = 'userName' >{props.user.name}</div>
            <div className = 'userEmail' >{props.user.email}</div>
            <div className = 'userRole' >{props.user.role}</div>
            {props.user.role === 'registered' ? 
            <div className = 'userButtons' >
                <div className = 'buttOK' onClick={(e) => props.handleUsers('approved', props.user.email)}>JÓVÁHAGY</div>
                <div className = 'buttNO' onClick={(e) => props.handleUsers('denied', props.user.email)}>ELUTASÍT</div>
            </div>
            : ''}
        </div>
     );
}
 
export default UserElement;