import axios, { AxiosResponse } from 'axios'
import IKnow from '../MODELS/know';
import IUser from '../MODELS/user'


let ftpArray: String[] = [];


export const getFtpData = (ftp: string, caller: string) => {
  return new Promise<any>((resolve, reject) => {
    axios.post<any>('https://homalyzona.herokuapp.com/sybrilloftp/getData', { ftp: ftp, caller : caller })
      .then((response: AxiosResponse) => { resolve(response.data);
      }).catch(error => { reject(error); })
  })
};


export const saveDatas = async (data : any, ftp: string, fileName: string) => {
  axios.post('https://homalyzona.herokuapp.com/sybrilloftp/writedata', { data: data, ftp: ftp, fileName :fileName })
  .then((response: AxiosResponse) => {
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });

  
};

let encode = ''

export const getFtpList = () => {

  return new Promise((resolve, reject) => {
    axios.get<IKnow>("https://homalyzona.herokuapp.com/sybrilloftp/getFtp")
    .then((response: AxiosResponse) => {
      
      response.data.map((element: any) => ( 
        console.log('ELEMENT.NAME ', element.name),
        encode = element.name.toString('utf-8'),
        ftpArray.push(encode) ))
      resolve(ftpArray)
    })
    .catch(error => {
      reject(error);
    })
  })
};






