import React, { useEffect, useState, useContext } from 'react'
import IPopup from '../MODELS/popup'
import PopupContext from '../MODELS/contexts/popupContext';
import Popup from '../COMPONENTS/Popup'
import RoutingController from './RoutingController';


interface IPopupControllerProps {

}

const PopupController: React.FunctionComponent<IPopupControllerProps> = (props) => {

  const [ popupState, setPopupState ] = useState<IPopup>({popupVisibility: 'popupContainerInvisible', popupStyle: '', popupTheme: '', popupTitle: '', popupMessage: ''});
  const updatePopupState = (popup: IPopup) => { setPopupState(popup) }

    const [ popupStyle, setPopupStyle ] = useState('popupHide');


    useEffect(() => {
      if(popupState.popupVisibility === 'popupContainer'){
        setTimeout(() => { 
          setPopupStyle('popupShow')
        }, 500)
      }
    }, [popupState])

    const handleClick = (click: string) => {
        setPopupStyle('popupHide');
        setTimeout(() => { 
            let popupStateTemp = JSON.parse(JSON.stringify(popupState))
            popupStateTemp.popupVisibility = 'popupContainerInvisible';
            updatePopupState(popupStateTemp)
         }, 1000)
      


     }

    return(
      <PopupContext.Provider value = {{popupState, updatePopupState}}>
        <div className = { popupState.popupVisibility }>
          <div className = { popupStyle }>
            <Popup handleClick = { handleClick }/>
          </div>
        </div>
        <RoutingController />
      </PopupContext.Provider>
    )

}

export default PopupController