import React, { useEffect, useState, useContext } from 'react';
import Search from '../COMPONENTS/Search';
import { getFile } from '../TOOLS/Download'
import { getJsons } from '../TOOLS/KnowQueries';
import KnowContext from '../MODELS/contexts/knowContext';
import UserContext from '../MODELS/contexts/userContext';

type FormValues = { searchWord: string; };

const SearchController: React.FunctionComponent = props => {

  const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = {
    menuContainer: "menuContainerSearch",
    buttonUp: "menuButtonUpKnow",
    buttonDown: "menuButtonDownAbout"
};

    const [ ok, setOk ] = useState(false);
    const [ foundList, setFoundList ] = useState([{ id: '', name: ''}]);
    const [ jsonList, setJsonList ] = useState<string[]>([]);
    const { knows } = useContext(KnowContext);
    const foundTemp: { id: string, name: string }[] = [];
    const { actualUser } = useContext(UserContext);
    

    useEffect(() => {
      createJsonList()
     }, [])

    
     const createJsonList = () => {

    getJsons('json.json')
      .then(response => { 
        setJsonList(response as any)
        let searchList = response
      })
      .catch(error => { console.log('GETJSONS ERROR ', error) })
    }

    const handleSearch = (searchWord: FormValues): void => {
      jsonList.map((know, index) => {
        if(know.toLowerCase().includes(searchWord.searchWord)){
          foundTemp.push(knows[index])
              }
      })
      setFoundList(foundTemp)
    }

    const getPdf = (pdfname: string) => { window.open('https://homalyzona.herokuapp.com/sybrilloftp/getfile/?filename=/' + pdfname + '.pdf', '_blank') }

    return(
      <>
        <Search menuObject = { menuObject } foundList = { foundList } getPdf = { getPdf } handleSearch = { handleSearch } actualUser = { actualUser }/>
      </>
     )

}

export default SearchController