import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import FormLogin from './FormLogin'
import FormRegister from './FormRegister'
import IUser from '../MODELS/user';



interface AuthProps {
  handleRegister: ( data: IUser ) => void;
  handleLogin: ( data: IUser ) => void;
  loggedUser: String;
}

type loginObject = { name: string, email: string, password: string }

const Auth: React.FunctionComponent<AuthProps> = props => {

    
    const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = {
        menuContainer: "menuContainerAuth",
        buttonUp: "menuButtonUpBlank",
        buttonDown: "menuButtonDownAbout"
    };

    useEffect(() => {
        
    }, [])

    return(
        <div className = 'authContainer'>
          <div className = 'actualUserContainer'>
            <div>Bejelentkezve: </div>
            <div>{' ' + props.loggedUser}</div>
          </div>
          <div className = 'formContainer'>
            <FormLogin handleLogin = { props.handleLogin } />
            <FormRegister handleRegister = { props.handleRegister } />
          </div>
          <Menu menuObject = { menuObject }/>
        </div>
        
     
    )

}

export default Auth