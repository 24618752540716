import React, { useEffect, useState, useContext } from 'react'
import '../STYLE/style.css'
import Menu from './Menu'
import Video from './Video.js'

interface GreetingProps {

}

const Greeting: React.FunctionComponent<GreetingProps> = props => {

    const menuObject: { menuContainer: string, buttonUp: string, buttonDown: string } = {
        menuContainer: "menuContainerGreeting",
        buttonUp: "menuButtonUpLogin",
        buttonDown: "menuButtonDownAbout"
    };

    useEffect(() => {
        
    }, [])


    return(
        <div className = 'greetingContainer'>
            <div className = 'videoContainer'><Video /></div>
            <Menu menuObject = { menuObject }/>
        </div>
        
     
    )

}

export default Greeting