import axios, { AxiosResponse } from 'axios'
import IKnow from '../MODELS/know';


export const getJsons = (jsonname: string) => {
  return new Promise((resolve, reject) => {
    axios.post<IKnow>('https://homalyzona.herokuapp.com/sybrilloknow/json', {
      jsonname : jsonname
    })
    .then((response: AxiosResponse) => { resolve(response.data.knowledges) })
    .catch( error => { reject(error) });
  })
};


